.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-200);
    transition: var(--animate);
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: var(--animate);
}

input:checked + .slider {
    background-color: var(--primary-600);
}

input:checked + .slider:hover {
    background-color: var(--primary-700);
}

input:focus + .slider {
    /* box-shadow: 0 0 1px #2196F3; */
}

input:focus-visible + .slider {
    outline: 4px solid var(--primary-100);
}

input:checked + .slider:before {
    transform: translateX(16px);
}

input:disabled + .slider {
    opacity: 0.3;
    cursor: default;
}

/* rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
