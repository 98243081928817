.wrapper {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
}

.text {
    align-items: center;
    margin-top: 8px;
    z-index: 1;
}

.icon {
    width: 54px;
    height: 54px;
    -webkit-animation: spin 1s cubic-bezier(0.670, 0.560, 0.060, 0.765) infinite;
    -moz-animation: spin 1s cubic-bezier(0.670, 0.560, 0.060, 0.765) infinite;
    animation: spin 1s cubic-bezier(0.670, 0.560, 0.060, 0.765) infinite;
    align-items: center;
    justify-content: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
}

@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}
