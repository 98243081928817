.icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon.cog svg, .icon.team svg,
.icon.exit svg, .icon.search svg {
    stroke: var(--gray-500);
}
