.content {
    position: relative;
    z-index: 100;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08), 0 8px 8px -4px rgba(16, 24, 40, 0.03);
    padding: 24px;
    min-width: 352px;
}

.close {
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 18px;
    right: 12px;
}