.menuButton {
    background-color: transparent;
    color: var(--primary-600);
    font-size: 12px;
    padding: 8px 12px;
    border: 1px solid transparent;
    border-radius: 3px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.menuButton:hover {
    background-color: var(--primary-100);
}

.menuButton:active {
    background-color: var(--primary-200);
}

.filterIcon {
    width: 18px;
    height: 18px;
}

.tagsWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}

.tag {
    background-color: white;
    color: var(--gray-900);
    font-size: 12px;
    padding: 5.5px 12px;
    border: 1px solid var(--gray-200);
    border-radius: 3px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
}

.closeTagIcon {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--gray-300);
    margin-top: 4px;
    cursor: pointer;
}

.wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.statusesMenu {
    left: -105px;
}

.itemStatusBadge {
    margin-left: 8px;
}

.statusBadge {
    padding: 0 8px !important;
    font-size: 10px !important;
}
