.wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icon {
    width: 14px;
    height: 14px;
}
