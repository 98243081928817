.icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon.small {
    width: 20px;
    height: 20px;
}


.icon img {
    width: 100%;
    object-fit: contain;
}
