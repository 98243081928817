.wrapper {
    display: inline-flex;
    padding: 2px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    line-height: 18px;
    align-items: center;
    justify-content: center;
}

.wrapper.green {
    background: var(--green-50);
    color: var(--green-700);
}

.wrapper.red {
    background: var(--primary-50);
    color: var(--primary-700);

}

.wrapper.gray {
    background: var(--gray-100);
    color: var(--gray-700);

}

.wrapper.yellow {
    background: var(--yellow-50);
    color: var(--yellow-700);
}
