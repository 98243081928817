.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.wrapper ul {
    padding: 0;
    display: flex;
    flex-direction: row;
    overflow: auto;
}

.tab {
    position: relative;
    cursor: pointer;
    justify-content: center;
    padding: 8px 10px;
    border-bottom: 1px solid var(--gray-200);
    display: inline-flex;
    flex: 1;
}

.tabButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: var(--gray-500);
}

.tabButton.active {
    color: var(--gray-800);
}

.tab.active:after {
    position: absolute;
    top: 98%;
    content: '';
    width: 100%;
    left: 0;
    height: 2px;
    background: var(--primary-700);
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
