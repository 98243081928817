.popover {
    position: absolute;
    top: 120%;
    left: 0;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 10;
}

.wrapper {
    position: relative;
}
