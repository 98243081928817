.header {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 32px;
}

.wrapper {
    position: relative;
}

.spinner {
    position: fixed;
    top: 0;
    left: var(--sidebar-full);
    width: calc(100% - var(--sidebar-full));
    height: 100%;
}

.spinner.smallSidebarSpinner {
    left: var(--sidebar-small);
    width: calc(100% - var(--sidebar-small));
}

.searchWrapper {
    flex: 1 100%;
    display: flex;
    justify-content: flex-end;
}

.user {
    display: flex;
    flex-direction: row;
}

.avatar {
    margin-right: 12px;
}

.icon {
    margin-right: 8px;
}

.thead {
    display: flex;
    align-items: center;
}

.table {
    height: calc(100vh - 180px);
    flex: auto!important;
}

.info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 14px;
}

.info .name {
    color: var(--gray-900)
}

.info .email {
    color: var(--gray-600)
}

.title {
    margin-right: 8px;
}

.membersTableWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.tableHeaderFilter {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
}
