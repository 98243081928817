.menuButton {
    background-color: var(--gray-200);
    color: var(--gray-900);
    font-size: 12px;
    padding: 8px 12px;
    border: 1px solid transparent;
    border-radius: 3px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: 150ms;
    outline: none;
}

.menuButton.open {
    border: 1px solid var(--primary-600);
    color: var(--primary-600);
    background-color: var(--primary-100);
}

.filterIcon {
    width: 18px;
    height: 18px;
}
