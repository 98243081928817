.wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.leftColumn {
    width: 50%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 5;
}

.rightColumn {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textContainer {
    max-width: 528px;
    position: relative;
    z-index: 3;
}

.background {
    position: absolute;
    /*width: 720px;*/
    right: 0;
    width: 38%;
    height: 100%;
    background: linear-gradient(222.78deg, #2E384D 21.67%, #151C2B 102.33%);
    z-index: 1;
}

.backgroundImage {
    position: absolute;
    top: 0;
    right: -14%;
    width: 100%;
    height: 100%;
    background: center / contain no-repeat url('../../assets/broFaces.png');
    z-index: 2;
}

.title {
    color: white;
    font-weight: 500;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
    white-space: initial;
    margin-bottom: 24px;
}

.description {
    color: var(--gray-200);
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    padding: 0;
}

.avatars {
    width: 152px;
    height: 40px;
}

.avatarsContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
}

.logo {
    width: 137px;
    height: 36px;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginForm {
    width: 320px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
    align-self: center;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--gray-100);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.submitButton {
    margin-top: 8px;
}

.footerText {
    padding: 0;
    color: var(--gray-600);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    justify-self: flex-end;
}

.spinner {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
}

.loginDescription {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 12px;
    color: var(--gray-600);
}

.email {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 12px;
    max-width: 360px;
    color: var(--primary-600);
}

.googleLoginButton {
    margin-top: 32px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: var(--gray-700);
}

.googleIcon {
    width: 22px;
    height: 22px;
}

.googleLoginLink {
    text-decoration-line: none;
}