.wrapper {
    padding-left: var(--sidebar-full);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

.wrapper.withSmallSidebar {
    padding-left: var(--sidebar-small);
}

.child {
    flex: 1 100%;
    display: flex;
    flex-direction: column;
    padding: 48px 32px;
}


.child.pure {
    padding: 0;
}
