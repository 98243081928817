.menuItem {
    background-color: white;
    color: var(--gray-900);
    padding: 12px 12px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    white-space: nowrap;
}

.menuItem:hover {
    background-color: var(--primary-50);
}

.menuItem:active, .menuItem.active {
    background-color: var(--primary-200);
}

.menuItem.selected {
    background-color: var(--primary-600);
    color: white;
    font-size: 12px;
    font-weight: 400;
}

.menuItemIcon {
    width: 14px;
    height: 14px;
    color: white;
}

.menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 5px;
}

.menu li:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.menu li:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
