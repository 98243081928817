.wrapper {
    min-width: 300px;
    display: flex;
}

.input {
    width: 100%;
    padding: 10px 14px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    outline: none;
    background: var(--snow);
    border: 1px solid var(--gray-300);
    color: var(--gray-900);
    font-size: 16px;
}

.input::placeholder {
    font-size: 16px;
    color: var(--gray-500);
}

.icon {
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
}

.icon svg {
    stroke: var(--gray-500);
}

.wrapper.withIcon {
    position: relative;
}

.input:focus + .icon svg{
    stroke: var(--gray-900);
}

.wrapper.withIcon .input {
    padding: 10px 14px 10px 42px;
}
