.container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.field {
    align-self: center;
}

.field.primary {
    color: var(--primary-700);
}

.field.secondary {
    color: var(--gray-700);
}

.field.normal {
    font-size: 14px;
}

.filed.large {
    font-size: 16px;
}

.field.regular {
    font-weight: 400;
}

.field.medium {
    font-weight: 500;
}

.field.bold {
    font-weight: 600;
}
