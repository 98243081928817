.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.contentWrapper {
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    overflow-x: auto;
}

.header {
    width: 100%;
    height: 240px;
    background: url('../../../assets/bg.png') fixed no-repeat;
}

@media all and (min-width: 1599px) {
    .header {
        background-size: cover;
    }
}

.info {
    margin-top: -32px;
    padding: 0 32px 32px 32px;
    display: flex;
    flex-direction: row;
}

.avatar {
    margin-right: 20px;
}

.name {
    font-weight: 600;
    font-size: 30px;
    flex: 1 100%;
    line-height: 38px;
    color: var(--gray-900);
    display: flex;
    align-items: center;
}

.nameWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}

.status {
    position: absolute;
    bottom: 0;
    left: 0;
}
