.wrapper {
    width: 100%;
    border-spacing: 0;
    color: var(--gray-900);
    border: none;
    border-radius: 10px;
}

.container {
    border: 1px solid var(--gray-200);
    border-radius: 10px;
}

.wrapper thead {
    position: relative;
}


.wrapper thead tr td {
    border-bottom: 1px solid var(--gray-200);
}

.container.sticky {
    flex: 1 100%;
}

.container.sticky thead {
    position: sticky;
    z-index: 1;
    top: 0;
}

.container.sticky thead tr td:first-child {
    z-index: 5;
}

.container {
    width: 100%;
    position: relative;
    overflow-y: auto;
}

.wrapper tbody td {
    padding: 16px 12px;
    border-spacing: 0;
}

.wrapper tbody tr:not(:last-child) {
    border-bottom: 1px solid var(--gray-100);
}

.wrapper tbody tr, .wrapper tbody tr td:first-child {
    cursor: pointer;
    background: var(--snow);
}

.wrapper tbody tr:nth-child(odd), .wrapper tbody tr:nth-child(odd) td:first-child {
    background: var(--gray-50)
}

.empty {
    display: flex;
    width: 100%;
    min-height: 300px;
    height: calc(100% - 55px); /* 100% - thead height*/
    align-items: center;
    justify-content: center;
    color: var(--gray-900);
}
.wrapper thead tr td {
    background: var(--snow);
    color: var(--gray-900);
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 16px 12px;
    position: relative;
    /* letter-spacing: 2px; */
}

.active {

}

.wrapper tr.firstSticky td:first-child {
    position: sticky;
    left: 0;
}

.sortIcon {
    margin-left: 6px;
}

.headerContent {
    position: relative;
    display: flex;
    align-items: center;
}
