.wrapper {
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.wrapper.big {
    width: 160px;
    height: 160px;
    border: 4px solid var(--snow);
    box-shadow: var(--shadow);
}

.wrapper.big .placeholder {
    font-size: 48px;
}

.placeholder {
    width: 100%;
    height: 100%;
    color: var(--primary-700);
    background: var(--primary-50);
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}

.wrapper img {
    width: 100%;
    height: 100%;
}
