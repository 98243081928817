.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 352px;
}

.title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: var(--gray-900);
    margin-top: 18px;
    text-align: center;
}

.description {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--gray-600);
    margin-top: 8px;
    text-align: center;
}

.buttonsContainer {
    display: flex;
    width: 100%;
    gap: 12px;
    margin-top: 32px;
}