.wrapper {
    margin: 12px 0 80px;
}

.headContainer {
    display: flex;
    justify-content: space-between;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.syncContainer {
    display: flex;
    align-items: center;
    gap: 16px;
}

.sync {
    font-size: 11px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
    letter-spacing: 2px !important;
    color: var(--gray-700) !important;
    text-transform: uppercase !important;
    padding: 1px 6px !important;
    background-color: transparent !important;
    border: transparent !important;
}

.sync.disabled {
    opacity: 0.3;
}

.helpIcon {
    cursor: pointer;
}

.title {
    font-size: 20px;
    line-height: 30px;
}

.title.withMargin {
    margin: 0 0 20px 0;
}

.description {
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-600);
    font-weight: 400;
    margin: 4px 0 20px 0;
}

/* info table styles */
.infoTable {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.infoTableItem {
    padding: 20px 0 20px 0;
    border-top: 1px solid var(--gray-200);
}

.infoTableItem.last {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.infoTableSocial {
    width: 20px;
    height: 20px;
}

.socialLabel {
    display: flex;
    padding: 0;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: var(--gray-600);
    cursor: pointer;
    border: none;
    background-color: transparent;
    white-space: nowrap;
}

.socialLabel.inactive {
    color: var(--gray-300);
    cursor: default;
}

.tooltip {
    max-width: 320px;
    padding: 12px;
    background-color: var(--gray-900);
    position: absolute;
    border-radius: 8px;
}

.tooltipTitle {
    font-weight: 600;
    font-size: 12px;
    color: white;
    margin-bottom: 4px;
}

.tooltipDescription {
    font-weight: 500;
    font-size: 12px;
    color: white;
}

/* integrations table */
.integrationsTable {
    display: grid;
    grid-template-columns: 340px 1fr;
}

.integrationsTableItem {
    padding: 20px 0 20px 0;
    border-top: 1px solid var(--gray-200);
}

.integrationsTableItemField {
    max-width: 280px;
}

.integrationsTableItem.last {
    text-align: left;
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
