.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: var(--sidebar-full);
    background: var(--snow);
    border-right: 1px solid var(--gray-200);
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.wrapper.minimize {
    width: var(--sidebar-small);
}

.logo {
    padding-left: 8px;
    margin-bottom: 24px;
}

.list {
    display: flex;
    flex-direction: column;
}

.list.links {
    flex: 1 100%;
}

.link {
    margin-bottom: 4px;
    padding: 10px 14px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-700);
    background-color: transparent;
    border-radius: 6px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    will-change: background-color;
    transition: background-color var(--animate);
    /*cursor: pointer;*/
}

.icon {
    margin-right: 12px;
}

.oAuthTitle {
    font-weight: 600;
    font-size: 16px;
    margin-left: 12px;
    margin-bottom: 4px;
}

.socialIcon {
    width: 24px !important;
    height: 24px !important;
    margin-left: 12px;
    margin-right: 12px;
}

.authButton {
    padding: 4px !important;
    font-size: 11px;
}

.socialsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.socialContainer {
    display: flex;
    align-items: center;
}


.wrapper.minimize .icon {
    margin-right: 0;
}


.wrapper.minimize .link {
    justify-content: center;
}

.link.active {
    background-color: var(--primary-50);
    color: var(--gray-800);
}

.link.active .icon svg {
    stroke: var(--primary-700) !important;
}

.avatar {
    min-width: 32px;
    width: 32px;
    min-height: 32px;
    height: 32px;
    margin-right: 10px;
}

.wrapper.minimize .avatar {
    min-width: 48px;
    width: 48px;
    min-height: 48px;
    height: 48px;
    margin-right: 0;
}

.text {
    flex: 1 100%;
    font-size: 14px;
}

.logout {
    margin-left: 12px;
}

.logoutButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.authStatusBadge {
    display: inline-flex;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-left: 12px;
}

.authStatusBadge.unauth {
    background-color: var(--error-500);
}

.authStatusBadge.auth {
    background-color: var(--green-500);
}
