@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import "react-tooltip/dist/react-tooltip.css";

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*vars*/
  --sidebar-full: 276px;
  --sidebar-small: 82px;

  --gray-900: #101828;
  --gray-800: #1D2939;
  --gray-700: #344054;
  --gray-600: #475467;
  --gray-500: #667085;
  --gray-300: #D0D5DD;
  --gray-250: #FCFCFD;
  --gray-200: #EAECF0;
  --gray-100: #F2F4F7;
  --gray-50: #F9FAFB;

  --primary-50: #FFF4F8;
  --primary-100: #FAE5ED;
  --primary-200: #FFBBD6;
  --primary-300: #FF8CB9;
  --primary-700: #B71556;
  --primary-600: #D91965;

  --green-50: #ECFDF3;
  --green-500: #12B76A;
  --green-700: #027A48;

  --yellow-50: #FFFAEB;
  --yellow-700: #B54708;

  --error-100: #FEE4E2;
  --error-300: #FDA29B;
  --error-500: #F04438;

  --snow: #FFFFFF;

  --animate: 0.2s ease-in-out;
  --shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  background: var(--gray-250);

  /* react-tooltip vars */
  --rt-color-dark: var(--gray-900);
  --rt-opacity: 1;
}
