/* common styles */

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    width: fit-content;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color var(--animate);
    position: relative;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container.loading {
    opacity: 0.3;
}

.button:disabled {
    cursor: default;
}

.button.fullWidth {
    width: 100%;
}

/* primary styles */

.button.primary {
    background-color: var(--primary-600);
    color: white;
}

.button.primary:hover {
    background-color: var(--primary-700);
}

.button.primary:disabled, .button.secondary:hover:disabled {
    background-color: var(--primary-200);
}

.button.primary:focus-visible {
    outline: 4px solid var(--primary-100);
}

/* secondary styles */

.button.secondary {
    background-color: white;
    border: 1px solid var(--gray-300);
}

.button.secondary:hover {
    background-color: var(--gray-50);
}

.button.secondary:disabled, .button.secondary:hover:disabled {
    color: var(--gray-300);
    border-color: var(--gray-200);
    background-color: white;
}

.button.secondary:focus-visible {
    outline: 4px solid var(--gray-100);
}

/* icon styles */

.icon, .icon > * {
    /* width: 16px; */
    /* height: 16px; */
}

.startIcon {
    margin-right: 10px;
}

.endIcon {
    margin-left: 10px;
}

/* button loading spinner */
.spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 5;
    opacity: 1 !important;
}

.spinner > div {
    display: none;
}
